
import { DealerTypes } from "@/store/modules/dealer/dealer.types";
import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";

const Dealer = namespace("Dealer");

@Component({
  components: {
    AgroDealerList: () => import("@/components/dealer/AgroDealerList.vue"),
  },
})
export default class DealersView extends Vue {
  @Dealer.Mutation(DealerTypes.SET_ADD_DEALER_DIALOG)
  public setAddDealer!: (addDealer: boolean) => void;

  @Dealer.Action(DealerTypes.LOAD_DEALERS)
  public loadDealers!: () => void;

  @Dealer.State("loadingDealer")
  public loadingDealers!: boolean;

  openAddDealerDialog(): void {
    this.setAddDealer(true);
  }
}
